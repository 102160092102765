
<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard class="cards">
          <form id="createSalesInvoices" @submit="createSalesInvoices">
            <CCardHeader>
              <CCol col="6" class="styleHeader">
                {{ $t("message.createPaymentInvoices") }}</CCol
              >
            </CCardHeader>
            <CCardBody>
              <CRow>
                   <CCol sm="6">
                  <div class="required form-group">
                    <label>
                      {{ $t("message.office") }}
                    </label>
                     <multiselect
                        class='required'
                        v-model='selectedOffice'
                        id='job_id'
                        :options='offices'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='name'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
                  </div>
                </CCol>
              </CRow>
              <hr />
              <CRow>
                <CCol sm="4">
                  <CInput
                    :label="$t('message.invoicePrice')"
                    id="invoice-price"
                    v-model="invoicePrice"
                    class="required"
                    :placeholder="$t('message.invoicePrice')"
                    :invalidFeedback="errors.invoicePrice"
                    required
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary">
                <CIcon name="cil-check-circle" />
                {{ $t("message.save") }}</CButton
              >
              &emsp;
              <CButton type="reset" size="sm" color="danger">
                <CIcon name="cil-ban" /> {{ $t("message.clear") }}
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'createOfficePayment',
  data: function () {
    return {
      invoicePrice: '',
      balance: '',
      offices: '',
      selectedOffice: '',
      errors: []
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}offices`)
      .then((response) => {
        this.offices = response.data.data
      })
  },
  methods: {
    async createSalesInvoices (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('price_paid', this.invoicePrice)
      formData.append('office_id', this.selectedOffice.id)
      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'sale-invoices',
        '/payments'
      )
    }
  }
}
</script>
<style scoped>
.order {
  background-color: #9daec5;
  color: #fff;
  padding: 15px;
  border-radius: 20%;
  font-size: 30px !important;
}
.add {
  background-color: #2a3647 !important;
  color: #fff !important;
  font-size: 15px !important;
}
.line {
  width: 80px;
  height: 4px;
  background: #2a3647;
  margin-bottom: 32px;
}
</style>
